module Main exposing (main)

import Browser exposing (Document)
import Bytes
import Classify
import Css exposing (alignItems, auto, backgroundColor, borderRadius, boxShadow4, center, color, displayFlex, fontFamilies, fontSize, height, justifyContent, lastChild, margin, margin2, marginBottom, marginLeft, maxWidth, padding, padding2, pct, pt, px, rgb, rgba, sansSerif, textAlign, vh, width)
import Css.Global
import DateOnly exposing (DateOnly)
import Euro exposing (Euro)
import Html.Styled exposing (Html, button, div, span, styled, text, toUnstyled)
import Html.Styled.Events exposing (onClick)
import IngCsv exposing (Transaction)
import List.Extra
import Task
import Url exposing (Url)
import WebDav



-- TODO: add documentation


main : Program String Model Msg
main =
    Browser.document
        { init = init
        , view = view
        , update = update
        , subscriptions = \_ -> Sub.none
        }


type Mode
    = Detailed
    | MonthlySummary


type Model
    = InitError String
    | Loading
    | Downloading
        { transactions : List Transaction
        , failCount : Int
        , restCount : Int
        }
    | Loaded
        { transactions : List TransactionVM
        , mode : Mode
        , text : String
        }


type alias TransactionVM =
    { transaction : Transaction
    , origin : String
    , category : String
    }


type Msg
    = DownloadComplete (Result () Bytes.Bytes)
    | NewListing (Result () (List Url))
    | ToggleMode


init : String -> ( Model, Cmd Msg )
init flags =
    case Url.fromString flags of
        Just url ->
            ( Loading
            , WebDav.findFiles url
                |> Task.map (List.filter (Url.toString >> String.endsWith ".csv"))
                |> Task.attempt NewListing
            )

        Nothing ->
            ( InitError <| "\"" ++ flags ++ "\"" ++ " is not a valid URL.", Cmd.none )


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    update1 msg model |> Tuple.mapFirst (handleDownloadRestCount msg)


handleDownloadRestCount : Msg -> Model -> Model
handleDownloadRestCount msg model =
    case ( model, msg ) of
        ( Downloading d, DownloadComplete _ ) ->
            if d.restCount == 1 then
                Loaded
                    { transactions = toVM d.transactions
                    , mode = Detailed
                    , text = "fail count: " ++ String.fromInt d.failCount
                    }

            else
                Downloading { d | restCount = d.restCount - 1 }

        _ ->
            model


update1 : Msg -> Model -> ( Model, Cmd Msg )
update1 msg model =
    case ( model, msg ) of
        ( Downloading m, DownloadComplete (Ok t) ) ->
            ( case IngCsv.decode t of
                Ok l ->
                    Downloading { m | transactions = m.transactions ++ l }

                Err _ ->
                    Downloading { m | failCount = m.failCount + 1 }
            , Cmd.none
            )

        ( Downloading m, DownloadComplete (Err _) ) ->
            ( Downloading { m | failCount = m.failCount + 1 }, Cmd.none )

        ( Loading, NewListing (Ok t) ) ->
            ( Downloading
                { transactions = []
                , restCount = List.length t
                , failCount = 0
                }
            , t
                |> List.map (WebDav.downloadFile >> Task.attempt DownloadComplete)
                |> Cmd.batch
            )

        ( Loading, NewListing (Err _) ) ->
            ( InitError "Failed to find files ", Cmd.none )

        ( Loaded m, ToggleMode ) ->
            ( Loaded
                { m
                    | mode =
                        case m.mode of
                            Detailed ->
                                MonthlySummary

                            MonthlySummary ->
                                Detailed
                }
            , Cmd.none
            )

        _ ->
            ( model, Cmd.none )


view : Model -> Document Msg
view model =
    { title = "Schatzmeister"
    , body =
        [ bodyStyle |> Css.Global.body |> List.singleton |> Css.Global.global
        , viewBody model
        ]
            |> List.map toUnstyled
    }


bodyStyle : List Css.Style
bodyStyle =
    [ fontFamilies [ "Arial", .value sansSerif ]
    , displayFlex
    , justifyContent center
    , margin (px 0)
    , height (vh 100)
    , backgroundColor (rgb 244 244 244)
    ]


viewBody : Model -> Html Msg
viewBody model =
    let
        barWidth =
            200

        barHeight =
            5

        loadingView p =
            styled div
                [ displayFlex
                , alignItems center
                ]
                []
                [ styled div
                    [ textAlign center ]
                    []
                    [ text "Lade Umsätze"
                    , styled div
                        [ margin2 (px 15) auto
                        , backgroundColor (rgb 200 200 200)
                        , width (px barWidth)
                        , height (px barHeight)
                        , borderRadius (px 5)
                        ]
                        []
                        [ styled div
                            [ backgroundColor (rgb 210 155 100)
                            , width (px (p * barWidth))
                            , height (px barHeight)
                            , borderRadius (px 5)
                            ]
                            []
                            []
                        ]
                    ]
                ]
    in
    case model of
        InitError txt ->
            text txt

        Downloading m ->
            let
                total =
                    toFloat <| List.length m.transactions + m.failCount + m.restCount
            in
            loadingView (1 - toFloat m.restCount / total)

        Loading ->
            loadingView 0

        Loaded m ->
            styled div
                [ width (pct 100)
                , maxWidth (px 500)
                ]
                []
                [ text <|
                    m.text
                        ++ ", total: "
                        ++ String.fromInt (List.length m.transactions)
                        ++ ", unknown: "
                        ++ String.fromInt
                            (m.transactions
                                |> List.filter (\t -> t.category == "???")
                                |> List.length
                            )
                        ++ ", error: "
                        ++ String.fromInt
                            (m.transactions
                                |> List.filter (\t -> t.category == "ERROR")
                                |> List.length
                            )
                , button [ onClick ToggleMode ]
                    [ text <|
                        case m.mode of
                            Detailed ->
                                "Übersicht"

                            MonthlySummary ->
                                "Details"
                    ]
                , case m.mode of
                    Detailed ->
                        viewTable (groupDay m.transactions)

                    MonthlySummary ->
                        viewMonthlySummaryTable (monthSummary m.transactions)
                ]


toVM : List Transaction -> List TransactionVM
toVM =
    List.sortBy (.valueDate >> DateOnly.toComparable)
        >> List.map
            (\t ->
                { transaction = t
                , origin =
                    Classify.originName t
                        |> Result.map (Maybe.withDefault t.transactor)
                        |> Result.withDefault t.transactor
                , category =
                    Classify.categoryName t
                        |> Result.map (Maybe.withDefault "???")
                        |> Result.withDefault "ERROR"
                }
            )


groupDay : List TransactionVM -> List ( DateOnly, List TransactionVM )
groupDay =
    List.Extra.groupWhile
        (\a b ->
            let
                f =
                    .transaction >> .valueDate >> DateOnly.toComparable
            in
            f a == f b
        )
        >> List.map (\( head, tail ) -> ( head.transaction.valueDate, head :: tail ))
        >> List.reverse


type alias TransactionSubCategoryGroup =
    { subCategory : String
    , total : Euro
    , transactionCount : Int
    }


type alias TransactionCategoryGroup =
    { category : String
    , total : Euro
    , subCategories : List TransactionSubCategoryGroup
    , transactionCount : Int
    }


monthSummary : List TransactionVM -> List ( ( Int, Int ), List TransactionCategoryGroup )
monthSummary =
    let
        groupMonth x =
            x |> DateOnly.toComparable |> (\( y, m, _ ) -> ( y, m ))

        byMonth : List TransactionVM -> List ( ( Int, Int ), List TransactionVM )
        byMonth =
            List.Extra.groupWhile
                (\a b ->
                    let
                        f =
                            .transaction >> .valueDate >> groupMonth
                    in
                    f a == f b
                )
                >> List.map (\( head, tail ) -> ( groupMonth head.transaction.valueDate, head :: tail ))
                >> List.reverse

        byCategory : List TransactionVM -> List TransactionCategoryGroup
        byCategory =
            List.sortBy .category
                >> List.Extra.groupWhile
                    (\a b ->
                        let
                            f =
                                .category
                        in
                        f a == f b
                    )
                >> List.map
                    (\( head, tail ) ->
                        let
                            all =
                                head :: tail
                        in
                        { category = head.category
                        , total = all |> List.map (.transaction >> .amount) |> List.foldl Euro.add (Euro.fromCents 0)
                        , subCategories = []
                        , transactionCount = List.length all
                        }
                    )
                >> List.reverse

        byMonthAndCategory : List TransactionVM -> List ( ( Int, Int ), List TransactionCategoryGroup )
        byMonthAndCategory =
            byMonth
                >> List.map
                    (Tuple.mapSecond
                        (\ts ->
                            let
                                incomes =
                                    ts |> List.filter (\t -> Euro.toCents t.transaction.amount > 0)

                                other =
                                    ts |> List.filter (\t -> Euro.toCents t.transaction.amount <= 0)
                            in
                            byCategory other ++ byCategory incomes
                        )
                    )
    in
    byMonthAndCategory


viewTable : List ( DateOnly, List TransactionVM ) -> Html msg
viewTable ts =
    div [] (ts |> List.map viewTransactionGroup)


viewTransactionGroup : ( DateOnly, List TransactionVM ) -> Html msg
viewTransactionGroup ( date, ts ) =
    styled div
        [ backgroundColor (rgb 255 255 255)
        , borderRadius (px 8)
        , boxShadow4 (px 0) (px 0) (px 10) (rgba 0 0 0 0.1)
        , padding (px 20)
        , marginBottom (px 5)
        , lastChild [ marginBottom (px 0) ]
        ]
        []
        (styled span
            [ fontSize (px 14)
            , color (rgb 51 51 51)
            , marginBottom (px 10)
            ]
            []
            [ text <| DateOnly.toString date ]
            :: (ts |> List.map viewTransaction)
        )


viewTransaction : TransactionVM -> Html msg
viewTransaction t =
    styled div
        [ displayFlex
        , padding2 (px 10) (px 0)
        ]
        []
        [ div []
            [ styled div [ fontSize (pt 13) ] [] [ text t.category ]
            , styled div
                [ color (rgb 128 128 128)
                , fontSize (pt 10)
                ]
                []
                [ text t.origin ]
            ]
        , styled div
            [ marginLeft auto
            ]
            []
            [ styled span
                ([ fontSize (pt 11)
                 , padding (px 2)
                 ]
                    ++ (if Euro.toCents t.transaction.amount > 0 then
                            [ backgroundColor (rgb 144 238 144) ]

                        else
                            []
                       )
                )
                []
                [ text <| Euro.toString t.transaction.amount ]
            ]
        ]


viewMonthlySummaryTable : List ( ( Int, Int ), List TransactionCategoryGroup ) -> Html msg
viewMonthlySummaryTable ts =
    div [] (ts |> List.map viewMonthGroup)


viewMonthGroup : ( ( Int, Int ), List TransactionCategoryGroup ) -> Html msg
viewMonthGroup ( date, ts ) =
    styled div
        [ backgroundColor (rgb 255 255 255)
        , borderRadius (px 8)
        , boxShadow4 (px 0) (px 0) (px 10) (rgba 0 0 0 0.1)
        , padding (px 20)
        , marginBottom (px 5)
        , lastChild [ marginBottom (px 0) ]
        ]
        []
        (styled span
            [ fontSize (px 14)
            , color (rgb 51 51 51)
            , marginBottom (px 10)
            ]
            []
            [ Tuple.mapBoth String.fromInt String.fromInt date |> (\( y, m ) -> m ++ "." ++ y) |> text ]
            :: (ts |> List.map viewMonthCategories)
        )


viewMonthCategories : TransactionCategoryGroup -> Html msg
viewMonthCategories t =
    styled div
        [ displayFlex
        , padding2 (px 10) (px 0)
        ]
        []
        [ div []
            [ styled div [ fontSize (pt 13) ] [] [ text t.category ]
            , styled div
                [ color (rgb 128 128 128)
                , fontSize (pt 10)
                ]
                []
                [ text <| String.fromInt t.transactionCount ]
            ]
        , styled div
            [ marginLeft auto
            ]
            []
            [ styled span
                ([ fontSize (pt 11)
                 , padding (px 2)
                 ]
                    ++ (if Euro.toCents t.total > 0 then
                            [ backgroundColor (rgb 144 238 144) ]

                        else
                            []
                       )
                )
                []
                [ text <| Euro.toString t.total ]
            ]
        ]
