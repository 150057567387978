import { Elm } from "./Main.elm";

Elm.Main.init({ flags: flags() });

function flags() {
    const hash = window.location.hash.substring(1);

    if (hash) {
        localStorage.setItem("data", hash);
    }

    return localStorage.getItem("data");
}
